import styled from 'styled-components';

export const Root = styled.div`
  display: flex;
  flex-direction: column;

  & > img {
    width: 100%;
    margin: 28px 0;
  }
`;

export const Separator = styled.hr`
  && {
    width: 100%;
    margin: 20px 0;
    height: 1.5px;
    background-color: ${({ theme }) => theme.colors.gray4};
  }
`;

export const Container = styled.div`
  background-color: #f3f3f3;
  width: 100%;
  min-height: 100vh;
`;
