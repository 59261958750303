import React from 'react';
import { useMessages } from '@campus/commons';
import { Button } from '@campus/components';
import { ArrowRepeat } from '@campus/components/icons';

import { Container, Title } from './styles';

const Header = () => {
  const { refreshData } = useMessages();

  return (
    <Container data-testid="skeleton-header">
      <div>
        <Title>Minhas Mensagens</Title>
        <Button onClick={() => refreshData()}>
          <ArrowRepeat size={24} />
        </Button>
      </div>
    </Container>
  );
};

export default Header;
