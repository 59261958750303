import React from 'react';

import { Content, MyTools } from '@campus/components';
import {
  DashboardSection,
  useStudentId,
  useWindowDimensions,
  HeaderMobile,
  MainBanner
} from '@campus/commons';

import VestibularIcon from '@campus/components/assets/icons/vestibular-icon.svg';
import { useNavigate } from 'react-router-dom';

import DisciplinesSection from './components/DisciplinesSection';
import TodaySection from './components/TodaySection';
import RevertColumn from './components/RevertColumn';

import { Root, Separator } from './styles';

const Home: React.FC = () => {
  const studentId = useStudentId();
  const { isMobile, breakpoint } = useWindowDimensions();

  const navigate = useNavigate();

  const tools = [
    {
      name: 'Campus Vestibular',
      click: () => {
        navigate('/vestibular');
      },
      icon: {
        src: VestibularIcon,
        alt: 'Campus vestibular',
        bgColor: '#F0F0F0'
      }
    }
  ];

  return (
    <div style={{ backgroundColor: '#F3F3F3' }}>
      <Content>
        <Root>
          {isMobile && (
            <HeaderMobile
              background="linear-gradient(313deg, #003C71 0%, #B7295A 100%)"
              title="Página Inicial"
              isExpandedHeader
            />
          )}
          <RevertColumn revert={isMobile}>
            {!isMobile ? <TodaySection /> : <></>}
            {isMobile ? <></> : <Separator />}
            <DashboardSection studentId={studentId} />
          </RevertColumn>
          <MainBanner />
          {isMobile ? <TodaySection /> : <></>}

          <MyTools tools={tools} marginTop={35} breakpoint={breakpoint} />

          <DisciplinesSection />
        </Root>
      </Content>
    </div>
  );
};

export default Home;
