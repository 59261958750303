import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { SideMenu, useSideMenu } from '@campus/components';
import { Variant } from '@campus/discipline';
import { ContextType, useAuthority } from '@campus/auth';
import {
  useContextManager,
  MenuHeader,
  useClassDisciplines
} from '@campus/commons';

import { Lists } from './styles';

const StudentMenu: React.FC<React.HTMLAttributes<HTMLElement>> = (props) => {
  const { search } = useLocation();
  const { currentContext } = useAuthority();

  const { period } = useContextManager();
  const { classDisciplines, loadingClassDisciplines } = useClassDisciplines(
    Variant.STUDENT
  );

  const { options } = useSideMenu();

  const isStudent = currentContext?.person?.contextType === ContextType.STUDENT;

  return (
    <SideMenu {...props}>
      <MenuHeader />
      <Link to={{ pathname: '/', search: search }}>
        <SideMenu.Option icon="home">Página inicial</SideMenu.Option>
      </Link>
      <Lists>
        <SideMenu.List title="Meus componentes curriculares">
          {loadingClassDisciplines ? (
            <>
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
              <SideMenu.ListItemSkeleton />
            </>
          ) : (
            <>
              {classDisciplines?.map((discipline) => (
                <Link
                  key={discipline.id}
                  to={{
                    pathname: `/discipline/${discipline.id}`,
                    search: search
                  }}
                >
                  <SideMenu.ListItem
                    title={discipline.name}
                    color={discipline.color}
                    newContentAvailable={discipline.newContentAvailable}
                    performanceStatusColor={discipline.performanceStatusColor()}
                    weightEvaluationsAvarege={
                      discipline.weightEvaluationsAvarege
                    }
                    badgeValue={
                      options.variant === 'default' &&
                      discipline.pendingCount > 0
                        ? `${discipline.pendingCount}`
                        : undefined
                    }
                  />
                </Link>
              ))}
            </>
          )}
        </SideMenu.List>
      </Lists>
      {isStudent && (
        <>
          {/* <SideMenuOption icon="forum">Fórum</SideMenuOption> */}
          {period.active && (
            <Link to={{ pathname: 'performace', search: search }}>
              <SideMenu.Option icon="grade">
                Meu Desempenho Campus
              </SideMenu.Option>
            </Link>
          )}
          <Link to={{ pathname: 'messages', search: search }}>
            <SideMenu.Option icon="message">Minhas mensagens</SideMenu.Option>
          </Link>
        </>
      )}
    </SideMenu>
  );
};

export default StudentMenu;
