import React from 'react';
import DisciplineModule, { Variant } from '@campus/discipline';
import { useStudentId } from '@campus/commons';

const Discipline = () => {
  const studentId = useStudentId();
  return <DisciplineModule variant={Variant.STUDENT} variantId={studentId} />;
};

export default Discipline;
