import React from 'react';
import { Avatar } from '@campus/components';
import ProIcon from '@campus/components/assets/icons/proIcon.svg';
import ProIconHigher from '@campus/components/assets/icons/proIconHigher.svg';

import { ImageStyled } from './styles';

interface IMessageAvatar {
  id: string;
  image?: string;
  name: string;
  size?: number;
}

const idBot = '00000000-0000-0000-0000-000000000000';

const MessageAvatar = ({ id, name, image, size = 56 }: IMessageAvatar) => {
  const showAvatarBot = id === idBot;

  if (showAvatarBot) {
    return (
      <div style={{ width: size }}>
        <ImageStyled src={size >= 56 ? ProIconHigher : ProIcon} $size={size} />
      </div>
    );
  }

  return (
    <div style={{ width: size }}>
      <Avatar name={name} image={image} size={size} />
    </div>
  );
};

export default MessageAvatar;
