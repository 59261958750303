import styled from 'styled-components';

export const Container = styled.div<{ $color?: string }>`
  height: 102px;

  max-width: 389px;
  margin-bottom: 14px;
  background-color: ${(props) =>
    props.$color || props.theme.colors.content.primaryColor};

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: 40px;
    max-width: 100%;
  }

  div {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 14px;
    display: flex;
    align-items: self-end;
    justify-content: end;
  }

  button {
    padding: 4px;
    margin-bottom: -2px;
    background: transparent;
    border: 0;
  }
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  color: #ffffff;
  flex: 1;
  margin: 0;
`;
