import React from 'react';
import { HeaderMobile, Message, useWindowDimensions } from '@campus/commons';

import MessageAvatar from 'pages/Message/components/MessageAvatar';

import CardEmpty from '../CardEmpty';
import Header from './components/Header';
import Item from './components/Item';

import {
  Container,
  Content,
  ContentBlock,
  ContainerHeader,
  StyledListGroup,
  ContainerFooter,
  SubTitleContent,
  LineBreak,
  UserInfo,
  UserName,
  UserRowName
} from './styles';

interface IMessageContent {
  message?: Message;
  isEmpty?: boolean;
}

const MessageContent = ({ message, isEmpty }: IMessageContent) => {
  const { isMobile } = useWindowDimensions();

  if (isEmpty) {
    return <CardEmpty subTextView={true} width={81} height={64.8} />;
  }

  if (!message) return null;

  return (
    <Container>
      {isMobile && (
        <HeaderMobile
          background="linear-gradient(313deg, #003C71 0%, #B7295A 100%)"
          title="Minhas Mensagens"
          subtitle="Fique por dentro das atividades e interações."
          style={{ padding: 15 }}
          backgroundStyle={{ height: 72 }}
        />
      )}
      <Header message={message} />
      <Content>
        <ContentBlock>
          <ContainerHeader
            dangerouslySetInnerHTML={{ __html: message.content.headerMessage }}
          />
          <StyledListGroup>
            {message.content.contents.map((item) => (
              <Item content={item} />
            ))}
          </StyledListGroup>
          <ContainerFooter
            dangerouslySetInnerHTML={{ __html: message.content.footerMessage }}
          />
          <LineBreak />
          <UserInfo data-testid="user-info">
            <MessageAvatar
              id={message?.creationUser?.id}
              name={message?.creationUser?.name}
              image={message?.creationUser?.imageUrl}
              size={64}
            />
            <div>
              <UserRowName>
                <UserName>{message?.creationUser?.name}</UserName>
                <SubTitleContent>Campus Ecossistema</SubTitleContent>
              </UserRowName>
            </div>
          </UserInfo>
        </ContentBlock>
      </Content>
    </Container>
  );
};

export default MessageContent;
