import styled from 'styled-components';
import { CampusIcon } from '@campus/components';

export const LibraryObjectContainer = styled.div`
  height: 100vh;
  width: 100%;
`;

export const HeaderLibraryObject = styled.header`
  background: ${(props) => props.theme.colors.CSA.darkBlue};
  height: 54px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  padding: 14px 10px 10px 20px;
`;

export const TitleLibraryObject = styled.span<{ $textLength: number }>`
  color: #fff;
  ${(props) =>
    props.$textLength > 100 ? 'font-size: 16px;' : 'font-size: 20px;'}
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
  margin-left: 12px;
  display: flex;
  align-items: center;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    font-size: 15px;
    font-weight: 600;
    width: 200px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    margin-top: auto;
    margin-bottom: auto;
  }
`;

export const ImageStyled = styled.img<{ $responsive?: boolean }>`
  display: block;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: ${(props) => (props.$responsive ? 'block' : 'none')};
  }
`;

export const ImageMobileStyled = styled.img`
  display: none;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: block;
  }
`;

export const HorizontalHr = styled.hr`
  width: 2px;
  height: 26px;
  opacity: 0.5;
  background: #fff;
  margin: 0px 0px 0px 10px;
`;

export const DivToBack = styled.div`
  width: 140px;
  display: flex;
  justify-content: space-between;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    width: 60px;
  }
`;

export const CampusIconStyled = styled(CampusIcon)`
  transform: rotate(180deg);
`;

export const CopyButton = styled.div`
  display: flex;
  align-items: center;
  margin-left: 14px;
`;

export const CloseButton = styled.button`
  border-radius: 6px;
  border: 1px solid ${(props) => props.theme.colors.gray200};
  background: ${(props) => props.theme.colors.CSA.darkBlue};
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px;
  margin-right: 14px;
  height: 32px;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: none;
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
`;

export const Container = styled.div`
  position: relative;
  height: calc(100vh - 54px);
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  @media (min-width: 1398px) {
    height: 90%;
  }
  @media (min-width: 1567px) {
    height: 80%;
  }
  @media (min-width: 1765px) {
    height: 75%;
  }
`;

export const DigitalObjectBlockedContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const DigitalObjectBlockedImage = styled.img<{ $isVideo: boolean }>`
  width: calc(100vw - 30px);
  max-width: ${(props) => (props.$isVideo ? '1200px' : '570px')};
  height: auto;
  margin: 15px;
  border-radius: 8px;

  filter: blur(1px);
`;

export const ContainerBlockedContent = styled.div`
  width: max-content;
  position: relative;
  margin: auto;
`;

export const DivBlockedItem = styled.div`
  display: none;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: flex;
  }
`;

export const DivBlockedItemDescription = styled.div`
  display: flex;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: none;
  }
`;
