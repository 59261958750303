import styled from 'styled-components';
import { ListGroup } from '@campus/components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  /* height: 100vh; */
  padding-bottom: 15px;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    background-color: #f3f3f3;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 0 15px;
`;

export const ContentBlock = styled.div`
  width: 100%;
  max-width: 600px;
`;

export const ContainerHeader = styled.div`
  margin-top: 24px;
  width: 100%;
  color: #828282;
`;

export const StyledListGroup = styled(ListGroup)`
  margin-top: 33px;
  width: 100%;
`;

export const ContainerFooter = styled.div`
  width: 100%;
  color: #828282;
  margin-top: 24px;
`;

export const SubTitleContent = styled.h6`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 110%;
  color: ${(props) => props.theme.colors.gray4};
  margin: 0;
`;

export const LineBreak = styled.hr`
  margin-top: 18px;
  width: 100%;
  max-width: 600px;
  opacity: 0.2;
`;

export const UserInfo = styled.div`
  margin-top: 12px;
  width: 100%;
  max-width: 600px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const UserRowName = styled.div`
  margin-left: 13px;
`;

export const UserName = styled.h6`
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #2f80ed;
  margin: 0;
`;
