import React from 'react';
import { Container } from './styles';
import MessageNavigation from './components/MessageNavigation';
import MessageContent from './components/MessageContent';
import { useMessages, useWindowDimensions } from '@campus/commons';
import { Route, Routes } from 'react-router-dom';

const Message: React.FC = () => {
  const { isLoading, messages, onSelectMessage, messageSelected } =
    useMessages();

  const { isMobile } = useWindowDimensions();

  if (isMobile) {
    return (
      <Routes>
        <Route
          path="/"
          element={
            <MessageNavigation
              isLoading={isLoading}
              messages={messages}
              onSelectMessage={onSelectMessage}
            />
          }
        />
        <Route
          path="/message/:id"
          element={
            <MessageContent
              message={messageSelected}
              isEmpty={messages?.length === 0}
            />
          }
        />
      </Routes>
    );
  }

  return (
    <Container>
      <MessageNavigation
        isLoading={isLoading}
        messages={messages}
        onSelectMessage={onSelectMessage}
        messageSelected={messageSelected}
      />
      <MessageContent
        message={messageSelected}
        isEmpty={messages?.length === 0}
      />
    </Container>
  );
};

export default Message;
