import { generateColor } from '@campus/commons';

class Content {
  id?: string;

  name?: string;

  startTime?: Date;

  endTime?: Date;

  evaluationTypeName?: string;

  totalWeight?: number;

  disciplineName?: string;

  color?: string;

  evaluationTypeColor?: string;

  static fromJson(data: any, topicData?: any): Content {
    const content = new Content();
    content.id = data.Id;
    content.name = data.Name;
    content.startTime = new Date(data.StartTime);
    content.endTime = new Date(data.EndTime);
    content.evaluationTypeName = data.EvaluationTypeName;
    content.totalWeight = data.TotalWeight;
    content.disciplineName = data?.Discipline.Name;
    content.setColor();
    content.evaluationTypeColor = data?.EvaluationTypeColor;

    return content;
  }

  private setColor(): void {
    this.color = generateColor(
      `${this.disciplineName}${new Date().getDate()}${new Date().getMonth()}`
    );
  }
}

export default Content;
