import styled from 'styled-components';
import { Button, ListGroupItem } from '@campus/components';

export const Container = styled(ListGroupItem)`
display: flex;
flex-direction: row;
justify-content: center;
padding: 12px 0px;

border: 0;
border-radius: 2px;
`;

export const StyledButton = styled(Button)`
font-weight: 600;
font-size: 11px;
color: ${props => props.theme.colors.gray300} !important;
border-color: ${props => props.theme.colors.gray300} !important;
background: #FFFFFF;
padding: 3px 10px 1px 10px;
border-radius: 2px;

&:hover {
  background: #FFFFFF;
  border-color: ${props => props.theme.colors.gray300};
}
`;
