import { Api } from '@campus/auth';
import { Response } from '@campus/commons';
import ContentToday from 'models/ContentToday';

export const listClassTodayContent = async (
  studentId: string,
  classId: string,
  stageId: string
): Promise<Response<ContentToday[]>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/student/${studentId}/class/${classId}/content/today`,
      { params: { StageId: stageId } }
    );

    return Response.fromData(
      response.data.map((item) => ContentToday.fromJson(item)),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};
