import React from 'react';
import { Link } from 'react-router-dom';
import { CampusIcon } from '@campus/components';
import { Message, useWindowDimensions } from '@campus/commons';

import format from 'date-fns/format';
import ptBR from 'date-fns/locale/pt-BR';

import { Container, Content, DivArrow, Title, SubTitle } from './styles';

interface IMessageHeader {
  message?: Message;
}

const Header = ({ message }: IMessageHeader) => {
  const formatDate = (date: Date): string =>
    `${format(date, "EEEE - dd/MM/yyyy - HH'h'MM", { locale: ptBR })}`;
  const { isMobile } = useWindowDimensions();

  return (
    <Container data-testid="skeleton-header">
      {isMobile && (
        <Link to="/messages">
          <DivArrow>
            <CampusIcon
              name="arrowRight"
              color="#FFFFFF"
              size={8}
              className="mx-2"
            />
          </DivArrow>
        </Link>
      )}
      <Content>
        <Title>{message.title}</Title>
        <SubTitle>{formatDate(new Date(message.creationDate))}</SubTitle>
      </Content>
    </Container>
  );
};

export default Header;
