import styled from 'styled-components';
import { ListGroupItem, Col } from '@campus/components';

export const Container = styled(ListGroupItem)<{ selected: boolean }>`
  display: flex;
  align-items: center;
  padding: 10px;
  padding-left: 0;
  cursor: pointer;
  border: 0;
  border-bottom: 1px solid #e0e0e0;

  border-left: ${({ selected, theme }) =>
    selected
      ? `5px solid ${theme.colors.content.primaryColor};`
      : '5px solid #ffffff'};

  background-color: ${({ selected }) =>
    selected ? 'rgba(69, 122, 245, 0.05)' : 'white'};

  & * {
    cursor: pointer;
  }
`;

export const IsNew = styled.div`
  width: 20px;
  display: flex;
  justify-content: center;
`;

export const ContainerDescription = styled.div`
  display: block;
  padding-left: 8px;
  width: 90%;
`;

export const TextHeader = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 120%;
  color: ${({ theme }) => theme.colors.gray300};
  text-transform: uppercase;
`;

export const TextFooter = styled.label`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 120%;
  margin-top: -2px;
  color: ${({ theme }) => theme.colors.gray600};
  display: block;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 12px;
  }
`;

export const TextDate = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray500};
  display: block;
`;

export const TextHour = styled.label`
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  color: ${({ theme }) => theme.colors.gray300};
`;

export const ContainerData = styled.div``;

export const StyledCol = styled(Col)`
  margin-left: 8px;
`;
