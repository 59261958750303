import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';
import compareAsc from 'date-fns/compareAsc';

import {
  Breakpoints,
  useContextManager,
  useStudentId,
  useWindowDimensions
} from '@campus/commons';
import { Skeleton, Tag, TextAvatar, Card } from '@campus/components';

import ContentToday from 'models/ContentToday';
import { listClassTodayContent } from 'services/content';

import {
  StyledSection,
  Title,
  ContentSection,
  ContentTextSection,
  StyledButton,
  TitleMobile,
  HeaderMobile,
  HeaderMobileButton,
  StyledSectionMobile,
  StyledSpan
} from './styles';

const TodaySection: React.FC = () => {
  const [contents, setContents] = useState<ContentToday[]>([]);
  const [loading, setLoading] = useState(true);
  const { classroom, stage } = useContextManager();

  const navigate = useNavigate();
  const studentId = useStudentId();
  const { breakpoint } = useWindowDimensions();

  useEffect(() => {
    const getData = async () => {
      setLoading(true);
      const result = await listClassTodayContent(
        studentId,
        classroom.id,
        stage.id
      );

      if (result.data) {
        const contents = result.data
          .reduce<ContentToday[]>((acc, item) => [...acc, item], [])
          .sort((contentA, contentB) =>
            compareAsc(contentA.endTime, contentB.endTime)
          );

        setContents(contents);
      }

      setLoading(false);
    };

    getData();
  }, [classroom.id, stage.id, studentId]);

  const renderSkeletonItem = (index: number) => (
    <ContentSection>
      <Skeleton width="33px" height="33px" borderRadius="100%" />
      <ContentTextSection>
        <Skeleton width="200px" height="15px" />
        <div>
          <Skeleton width="70px" height="15px" />
          <Skeleton width="60px" height="15px" />
        </div>
      </ContentTextSection>
    </ContentSection>
  );

  const renderSkeleton = () => (
    <>
      {[...Array(6)].map((_, index) => (
        <>{renderSkeletonItem(index)}</>
      ))}
    </>
  );

  const renderContentsMobile = () => {
    if (loading) {
      return [...Array(4)].map((_, index) => <> {renderSkeletonItem(index)}</>);
    }

    if (!contents.length) {
      return (
        <StyledSpan $mobile>
          <strong>Não identificamos nenhuma atividade para hoje!</strong>
          Que tal usar este momento para planejar ou revisar seus estudos.
        </StyledSpan>
      );
    }

    return (
      <>
        {contents.slice(0, 4).map((content) => (
          <ContentSection key={content.id}>
            <TextAvatar text={content.disciplineName} color={content.color} />
            <ContentTextSection>
              <p>
                {format(content.startTime, 'dd')} a{' '}
                {format(content.endTime, 'dd/MM')} | {content.name}
              </p>
              <div>
                <Tag color={content.evaluationTypeColor}>
                  {content.evaluationTypeName}
                </Tag>
                <Tag>{content.totalWeight} PONTOS</Tag>
              </div>
            </ContentTextSection>
          </ContentSection>
        ))}
      </>
    );
  };

  const renderButtonMobile = () => {
    if (!!contents.length) {
      return (
        <HeaderMobileButton onClick={() => navigate('/messages')}>
          Ver todos ({contents.length})
        </HeaderMobileButton>
      );
    } else if (!contents.length && !loading) {
      return (
        <HeaderMobileButton onClick={() => navigate('/messages')}>
          Minhas Mensagens
        </HeaderMobileButton>
      );
    }
  };

  if (breakpoint === Breakpoints.xs) {
    return (
      <StyledSectionMobile>
        <HeaderMobile>
          <TitleMobile>O que fazer hoje?</TitleMobile>
          {renderButtonMobile()}
        </HeaderMobile>
        <Card>{renderContentsMobile()}</Card>
      </StyledSectionMobile>
    );
  }

  const renderContent = (content: ContentToday) => (
    <ContentSection key={content.id}>
      <TextAvatar text={content.disciplineName} color={content.color} />
      <ContentTextSection>
        <p>
          {format(content.startTime, 'dd')} a {format(content.endTime, 'dd/MM')}{' '}
          | {content.name}
        </p>
        <div>
          <Tag color={content.evaluationTypeColor}>
            {content.evaluationTypeName}
          </Tag>
          <Tag>{content.totalWeight} PONTOS</Tag>
        </div>
      </ContentTextSection>
    </ContentSection>
  );

  const renderContents = () => {
    if (loading) {
      return renderSkeleton();
    }

    if (!contents.length) {
      return (
        <StyledSpan>
          <strong>Não identificamos nenhuma atividade para hoje!</strong>
          Que tal usar este momento para planejar ou revisar seus estudos.
        </StyledSpan>
      );
    }

    return (
      <>
        {contents.slice(0, 6).map((content) => (
          <>{renderContent(content)}</>
        ))}
      </>
    );
  };

  const renderButton = () => {
    if (!!contents.length) {
      return (
        <StyledButton onClick={() => navigate('/messages')}>
          Ver todos ({contents.length})
        </StyledButton>
      );
    } else if (!contents.length && !loading) {
      return (
        <StyledButton onClick={() => navigate('/messages')}>
          Minhas Mensagens
        </StyledButton>
      );
    }
  };

  return (
    <StyledSection aria-label="O que fazer hoje">
      <Title>
        <span>O que fazer</span>
        <p>Hoje</p>
      </Title>
      {renderContents()}
      {renderButton()}
    </StyledSection>
  );
};

export default TodaySection;
