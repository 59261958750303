import { Api } from '@campus/auth';
import { PaginationModel, Response } from '@campus/commons';
import LibraryObject from 'models/LibraryObject';

export type LibraryObjectListFilters = {
  name?: string;
  contentType?: string;
  disciplineId?: string;
  gradeId?: string;
  studentId?: string;
  sorting?: string;
  skipCount?: number;
  maxResultCount?: number;
};

export const getLibraryObject = async (
  libraryObjectId: string
): Promise<Response<LibraryObject>> => {
  try {
    const response = await Api.INSTANCE.getApi().get(
      `/libraryobject/${libraryObjectId}`
    );

    return Response.fromData(
      LibraryObject.fromJson(response.data),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};

export const listLibraryObjects = async (
  filters: LibraryObjectListFilters
): Promise<Response<PaginationModel<LibraryObject>>> => {
  try {
    const { skipCount } = filters;

    const response = await Api.INSTANCE.getApi().get(`/libraryobject`, {
      params: filters
    });

    return Response.fromData(
      PaginationModel.fromJson(LibraryObject, response.data, skipCount),
      response.status
    );
  } catch (e: any) {
    return Response.error(e);
  }
};
