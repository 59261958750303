import React, { useCallback, useEffect, useState } from 'react';
import { useParams, useSearchParams, useNavigate } from 'react-router-dom';

import { OverlayTrigger, Tooltip, toast } from '@campus/components';
import campusLogoWhite from '@campus/components/assets/images/completeLogoWhite.svg';
import copyIconSvg from '@campus/components/assets/icons/copy.svg';
import campusSmallLogo from '@campus/components/assets/logo.svg';
import { BlockedItem, BlockedItemDescription } from '@campus/commons';

import { getLibraryObject } from 'services/libraryObject';
import LibraryObjectChapter from 'models/LibraryObjectChapter';
import LibraryObjectContentType from 'models/LibraryObjectContentType';

import {
  CampusIconStyled,
  CopyButton,
  ContainerBlockedContent,
  Container,
  CloseButton,
  DigitalObjectBlockedContent,
  DigitalObjectBlockedImage,
  DivBlockedItemDescription,
  DivBlockedItem,
  Iframe,
  LibraryObjectContainer,
  DivToBack,
  HeaderLibraryObject,
  HeaderLeft,
  HorizontalHr,
  ImageStyled,
  ImageMobileStyled,
  TitleLibraryObject
} from './styles';

const LibraryObjectScreen: React.FC = () => {
  const { libraryObjectId } = useParams<{
    libraryObjectId: string;
  }>();
  const navigate = useNavigate();
  const { chapterId } = useParams<{ chapterId?: string }>();
  const [libraryObjectChapter, setLibraryObjectChapter] =
    useState<LibraryObjectChapter>();
  const [searchParams] = useSearchParams();
  const origin = searchParams.get('origin');

  const fetchData = useCallback(
    async (libraryObjectIdParam) => {
      const result = await getLibraryObject(libraryObjectIdParam);

      if (!!chapterId) {
        setLibraryObjectChapter(
          result.data.chapters.find((c) => c.id === chapterId)
        );
      } else {
        setLibraryObjectChapter(result.data.chapters[0]);
      }
    },
    [chapterId]
  );

  useEffect(() => {
    if (libraryObjectId) {
      fetchData(libraryObjectId);
    }
  }, [fetchData, libraryObjectId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    toast.success('Link Copiado!');
  };

  return (
    <LibraryObjectContainer>
      <HeaderLibraryObject>
        <HeaderLeft>
          <DivToBack
            role="button"
            onClick={() => {
              navigate(origin ?? '/');
            }}
          >
            <CampusIconStyled name="arrowRight" color="#B3B3B3" />
            <ImageStyled src={campusLogoWhite} alt="Logo do Campus" />
            <ImageMobileStyled src={campusSmallLogo} alt="Logo do Campus" />
          </DivToBack>
          {!!libraryObjectChapter?.name && (
            <>
              <HorizontalHr />
              <TitleLibraryObject
                $textLength={libraryObjectChapter?.name?.length ?? 0}
              >
                {libraryObjectChapter?.name}
              </TitleLibraryObject>

              <OverlayTrigger
                placement="right"
                overlay={(props) => (
                  <Tooltip id="tooltip-copy-link" {...props}>
                    Copiar link
                  </Tooltip>
                )}
              >
                <CopyButton role="button" onClick={handleCopyLink}>
                  <ImageStyled
                    $responsive
                    src={copyIconSvg}
                    alt="Copiar link"
                    width={18}
                    height={18}
                  />
                </CopyButton>
              </OverlayTrigger>
            </>
          )}
        </HeaderLeft>

        <CloseButton
          onClick={() => {
            navigate(origin ?? '/');
          }}
        >
          Fechar
        </CloseButton>
      </HeaderLibraryObject>

      {!!libraryObjectChapter?.name && libraryObjectChapter.contentUrl && (
        <Container>
          <Iframe
            src={`${libraryObjectChapter.contentUrl}?autopause=0`}
            title={libraryObjectChapter.name}
            allowFullScreen
          />
        </Container>
      )}
      {!!libraryObjectChapter?.name &&
        !!libraryObjectChapter.imageUrl &&
        !libraryObjectChapter.contentUrl && (
          <ContainerBlockedContent>
            <DigitalObjectBlockedContent>
              <DigitalObjectBlockedImage
                src={libraryObjectChapter.imageUrl}
                $isVideo={
                  libraryObjectChapter.contentType ===
                  LibraryObjectContentType.VIDEO
                }
              />
              <DivBlockedItem>
                <BlockedItem />
              </DivBlockedItem>
              <DivBlockedItemDescription>
                <BlockedItemDescription />
              </DivBlockedItemDescription>
            </DigitalObjectBlockedContent>
          </ContainerBlockedContent>
        )}
    </LibraryObjectContainer>
  );
};

export default LibraryObjectScreen;
