import { Breakpoints } from '@campus/commons';

export const breakpointsAndCardsPerRow = {
  [Breakpoints.xs]: 2,
  [Breakpoints.sm]: 3,
  [Breakpoints.md]: 4,
  [Breakpoints.lg]: 3,
  [Breakpoints.xl]: 5,
  [Breakpoints.xxl]: 5
} as const;
