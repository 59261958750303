import styled from 'styled-components';

export const Container = styled.div<{ $color?: string }>`
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 102px;
  background-color: #457af5;
  padding: 0 15px;
  padding-bottom: 13px;

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: 72px;
    min-height: 72px;
    padding-bottom: 0;
    align-items: center;
  }
`;

export const Content = styled.div`
  max-width: 600px;
  width: 100%;
`;

export const Title = styled.h1`
  font-weight: 700;
  font-size: 24px;
  line-height: 110%;
  color: #ffffff;
  margin: 0;
  white-space: nowrap;

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 18px;
  }
`;

export const SubTitle = styled.h6`
  font-weight: 500;
  font-size: 12px;
  line-height: 120%;
  color: #ffffff;
  white-space: nowrap;
  text-transform: capitalize;
  margin: 0;
`;

export const DivArrow = styled.div`
  opacity: 0.4;
  transform: rotate(180deg);
  margin-right: 10px;
`;
