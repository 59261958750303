import React from 'react';
import { Message } from '@campus/commons';
import CircleNew from '@campus/components/assets/icons/circle-new.svg';
import format from 'date-fns/format';

import {
  Container,
  IsNew,
  ContainerDescription,
  TextHeader,
  TextFooter,
  ContainerData,
  TextDate,
  TextHour
} from './styles';

import MessageAvatar from '../../../MessageAvatar';

interface ICardMessage {
  message: Message;
  selected: boolean;
  onClick: (message: Message) => void;
}

const CardMessage = ({ message, selected, onClick }: ICardMessage) => {
  const formatDate = (date: Date) => format(date, 'dd/MM');
  const formatHour = (date: Date) => format(date, "hh'h'mm");
  const isNew =
    message.content.contents.filter((item) => item.isNew).length > 0;

  return (
    <Container selected={selected} onClick={onClick}>
      <IsNew>{isNew && <img src={CircleNew} alt="Nova Mensagem" />}</IsNew>
      <MessageAvatar
        id={message?.creationUser?.id}
        name={message?.creationUser?.name}
        image={message?.creationUser?.imageUrl}
        size={32}
      />
      <ContainerDescription>
        <TextHeader>{message.creationUser.name}</TextHeader>
        <TextFooter>{message.title}</TextFooter>
      </ContainerDescription>
      <ContainerData>
        <TextDate>{formatDate(new Date(message.creationDate))}</TextDate>
        <TextHour>{formatHour(new Date(message.creationDate))}</TextHour>
      </ContainerData>
    </Container>
  );
};

export default CardMessage;
