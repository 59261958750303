import React, { useRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Content } from '@campus/components';
import {
  HeaderMobile,
  StudentPerformanceToPrint,
  StudentPerformanceMobile,
  useStudentId,
  useWindowDimensions
} from '@campus/commons';

import {
  Banner,
  ChangeViewButton,
  DivMobile,
  DivToPrint,
  DivToPrintParent,
  Header,
  HeaderContent
} from './styles';
import { toCanvas } from 'html-to-image';
import jsPdf from 'jspdf';

const Performace: React.FC = () => {
  const studentId = useStudentId();
  const { isMobile } = useWindowDimensions();
  const componentRefFirstPage = useRef(null);
  const componentRefSecondPage = useRef(null);
  const [searchParams] = useSearchParams();
  const toPrintPdf = searchParams.get('toPrintPdf') === 'true';

  const printPDF = async (closeWindow?: boolean) => {
    const pdf = new jsPdf({ orientation: 'landscape' });
    const canvasFirstPage = await toCanvas(componentRefFirstPage.current);
    const imageData = canvasFirstPage.toDataURL('image/png');
    const height = pdf.internal.pageSize.getHeight() * 0.925;
    const width = pdf.internal.pageSize.getWidth() * 0.925;
    let widthFirstPage =
      componentRefFirstPage.current.offsetWidth *
      (height / componentRefFirstPage.current.offsetHeight);

    if (widthFirstPage > width) {
      widthFirstPage = width;
    }

    pdf.addImage({
      imageData,
      format: 'PNG',
      x: (width - widthFirstPage) / 2 + 10,
      y: 5,
      width: widthFirstPage,
      height
    });

    if (!!componentRefSecondPage?.current) {
      const canvasSecondPage = await toCanvas(componentRefSecondPage.current);
      const imageDataSecond = canvasSecondPage.toDataURL('image/png');
      pdf.addPage();

      let heightSecondPage =
        componentRefSecondPage.current.offsetHeight *
        (width / componentRefSecondPage.current.offsetWidth);

      if (widthFirstPage > width) {
        widthFirstPage = width;
      }

      pdf.addImage({
        imageData: imageDataSecond,
        format: 'PNG',
        x: (width - widthFirstPage) / 2 + 10,
        y: 5,
        width: widthFirstPage,
        height: heightSecondPage
      });
    }

    pdf.save(`${new Date().toISOString()}.pdf`);

    if (closeWindow) {
      window.close();
    }
  };

  return (
    <>
      {isMobile && (
        <HeaderMobile
          background="linear-gradient(313deg, #003C71 0%, #B7295A 100%)"
          title="MDC Consolidado"
          style={{ padding: '16px 20px 8px 20px' }}
          backgroundStyle={{ height: 72 }}          
          subtitle="Acompanhe suas notas detalhadas em tempo real."
        />
      )}
      <Banner>
        <Header>
          <HeaderContent>
            <h1>Meu Desempenho Campus</h1>
            <h2>Acompanhe suas notas detalhadas em tempo real</h2>
          </HeaderContent>
          <ChangeViewButton
            onClick={() => {
              printPDF();
            }}
          >
            Baixar Boletim (.pdf)
          </ChangeViewButton>
        </Header>
      </Banner>
      <Content>
        <DivToPrintParent $toPrintPdf={toPrintPdf}>
          <DivToPrint id="ref-component">
            <StudentPerformanceToPrint
              studentId={studentId}
              refFirstPage={componentRefFirstPage}
              refSecondPage={componentRefSecondPage}
              toPrintPdf={toPrintPdf}
              printPDF={printPDF}
            />
          </DivToPrint>
        </DivToPrintParent>
        <DivMobile $toPrintPdf={toPrintPdf}>
          <StudentPerformanceMobile studentId={studentId} />
        </DivMobile>
      </Content>
    </>
  );
};

export default Performace;
