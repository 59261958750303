import React from 'react';
import { MessagesProvider, useContextManager } from '@campus/commons';

import Message from './Message';

const MessageSource: React.FC = () => {
  const { classroom, stage } = useContextManager();

  return (
    <MessagesProvider classroom={classroom} stage={stage}>
      <Message />
    </MessagesProvider>
  );
};

export default MessageSource;
