import styled from 'styled-components';
import { Card, Button } from '@campus/components';

export const StyledSection = styled(Card)`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.gray5};
  overflow: hidden;
  background-color: #fff;
  align-items: stretch;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 180px;
    display: block;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 1) 15%
    );
  }
`;

export const Title = styled.div`
  line-height: 1;
  padding: 14px;
  border-right: 1px solid ${(props) => props.theme.colors.gray5};

  & > span {
    font-size: 12px;
    font-weight: ${({ theme }) => theme.weight.bold};
    color: ${({ theme }) => theme.colors.gray2};
    white-space: nowrap;
  }

  & > p {
    margin: 0;
    font-size: 26px;
    font-weight: ${({ theme }) => theme.weight.extraBold};
    text-transform: uppercase;
    background: linear-gradient(
      313.31deg,
      ${({ theme }) => theme.colors.CSA.green} 0%,
      ${({ theme }) => theme.colors.CSA.cyan} 100%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
`;

export const StyledSectionMobile = styled.div`
  margin-top: 20px;
`;

export const HeaderMobile = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

export const HeaderMobileButton = styled(Button)`
  display: flex;
  height: 24px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const TitleMobile = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: #4f4f4f;
`;

export const ContentSection = styled.div`
  padding: 14px;
  border-left: 1px solid ${(props) => props.theme.colors.gray5};
  display: flex;
  align-items: center;
  gap: 10px;

  ${(props) => props.theme.breakpoints.down('sm')} {
    border-left: none;
    align-items: center;

    &:not(:last-child) {
      border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
    }
  }
`;

export const ContentTextSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3px;

  & > p {
    margin: 0;
    font-size: 14px;
    font-weight: ${(props) => props.theme.weight.semiBold};
    line-height: 1.1;
    color: ${(props) => props.theme.colors.gray3};
    white-space: nowrap;
  }

  & > div {
    display: flex;
    gap: 4px;
  }
`;

export const StyledButton = styled(Button)`
  position: absolute;
  right: 14px;
  top: 25%;
  font-weight: ${(props) => props.theme.weight.semiBold};
  font-size: 14px;
`;

export const StyledSpan = styled.span<{ $mobile?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: ${({ $mobile }) => ($mobile ? '15px 17px' : '0px 17px')};
  justify-content: center;
  color: ${({ theme }) => theme.colors.gray3};
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 110%;

  strong {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.blue1};
  }
`;
