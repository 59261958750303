import React from 'react';
import { RevertColumnContainer } from './styles';

interface RevertColumnProps {
  revert?: boolean;
}

const RevertColumn: React.FC<RevertColumnProps> = ({ children, revert }) => {
  return (
    <RevertColumnContainer revert={revert}>{children}</RevertColumnContainer>
  );
};

export default RevertColumn;
