import React from 'react';
import { HeaderMobile, Message, useWindowDimensions } from '@campus/commons';
import { NavigationContainer } from './styles';
import Header from './components/Header';
// import Filter from './components/Filters';
import Content from './components/Content';

interface IMessageNavigation {
  isLoading: boolean;
  messages?: Array<Message>;
  onSelectMessage?: (message: Message) => void;
  messageSelected?: Message;
}

const MessageNavigation = ({ ...props }: IMessageNavigation) => {
  const { isMobile } = useWindowDimensions();

  return (
    <NavigationContainer>
      {isMobile && (
        <HeaderMobile
          background="linear-gradient(313deg, #003C71 0%, #B7295A 100%)"
          title="Minhas Mensagens"
          subtitle="Fique por dentro das atividades e interações."
          style={{ padding: 15 }}
          backgroundStyle={{ height: 72 }}
        />
      )}
      {!isMobile && <Header />}
      {/* <Filter /> */}
      <Content {...props} />
    </NavigationContainer>
  );
};

export default MessageNavigation;
