import styled from 'styled-components';
import { ListGroupItem, Badge } from '@campus/components';

export const StyledListGroupItem = styled(ListGroupItem)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 16px 10px 10px;
  cursor: pointer;
`;

export const StyledListGroupItemContent = styled(ListGroupItem)`
  display: flex;
  border: 0;
  padding: 0;
`;

export const StyledRowContent = styled(ListGroupItem)`
  display: flex;
  border: 0;
  padding: 0;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    flex-direction: column;
    gap: 4px;
  }
`;

export const DisciplineIcon = styled.div`
  border: 2px solid ${(props) => props.color};
  color: ${(props) => props.color};
  width: 39px;
  height: 39px;
  font-size: 14px;
  text-transform: capitalize;
  margin-right: 9px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
`;

export const TitleContent = styled.h5`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: ${(props) => props.theme.colors.gray3};
  margin: 0;
  margin-right: 10px;
`;

export const SubTitleContent = styled.h6`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 110%;
  color: ${(props) => props.theme.colors.gray4};
  margin: 0;
  margin-top: 3px;

  display: flex;

  span {
    color: #808080;
    padding-left: 8px;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 12px;
    flex-direction: column;

    span {
      padding-left: 0;
    }
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    margin-top: 8px;
  }
`;

export const StyledBadgeEvaluationTypeName = styled(Badge)<{
  $color?: string;
}>`
  font-weight: 800;
  font-size: 10px;
  line-height: 110%;
  background-color: ${(props) =>
    `${
      props.theme.colors[props.$color]
        ? `hsl(${props.theme.colors[props.$color]})1A`
        : `hsl(${props.$color}, 0.15)`
    }`} !important;
  border-radius: 2px;
  color: ${(props) => (props.$color ? `hsl(${props.$color})` : '#0072ce')};
  margin-right: 5%;
  text-transform: uppercase;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    margin-right: 4px;
  }
`;

export const StyledBageWeight = styled(Badge)`
  border: 1px solid ${(props) => props.theme.colors.gray300};
  border-radius: 2px;
  font-weight: 800;
  font-size: 10px;
  line-height: 110%;
  color: ${(props) => props.theme.colors.gray300};
  background-color: transparent !important;
  width: 70px;
  padding: 2px 4px;
  text-transform: uppercase;
`;

export const StyledColTitle = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledColBadge = styled.div`
  display: flex;
  align-items: center;
`;
