import React from 'react';
import { ListGroup, Skeleton } from '@campus/components';
import { Message, useWindowDimensions } from '@campus/commons';

import { Container, StyledCard, SkeletoonRoot } from './styles';
import CardMessage from '../CardMessage';
import LoadMore from '../LoadMore';
import CardEmpty from '../../../CardEmpty';
import { useNavigate } from 'react-router-dom';

interface IContentMessage {
  isLoading: boolean;
  messages?: Array<Message>;
  onSelectMessage?: (message: Message) => void;
  messageSelected?: Message;
}

const Content = ({
  isLoading,
  messages,
  onSelectMessage,
  messageSelected
}: IContentMessage) => {
  const { isMobile } = useWindowDimensions();
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <Container>
        <SkeletoonRoot>
          <Skeleton width="100%" height="400px" borderRadius="7px" />
        </SkeletoonRoot>
      </Container>
    );
  }

  if (messages?.length === 0) {
    return <CardEmpty subTextView={false} width={40} height={32} />;
  }

  return (
    <Container>
      <StyledCard>
        <ListGroup>
          {messages?.map((item: Message, index) => (
            <CardMessage
              message={item}
              selected={Object.is(item, messageSelected)}
              onClick={() => {
                onSelectMessage(item);
                if (isMobile) {
                  navigate('/messages/message/' + index);
                }
              }}
            />
          ))}
          {messages?.length > 0 && <LoadMore />}
        </ListGroup>
      </StyledCard>
    </Container>
  );
};

export default Content;
