import React from 'react';
import { Route } from 'react-router-dom';
import { studentTheme } from '@campus/components';

import { ContextType, useAuthority, PersonType } from '@campus/auth';
import {
  AccessTimeProvider,
  AppContainer,
  ContextManagerProvider,
  ClassDisciplinesProvider,
  MainProviderProps,
  PeriodBanner,
  Variant
} from '@campus/commons';

import { createVestibularRoutes } from '@campus/vestibular';

import Home from 'pages/Home';
import Discipline from 'pages/Discipline';

import Performace from 'pages/Performace';
import Message from 'pages/Message';
import LibraryObject from 'pages/LibraryObject';

import StudentMenu from 'containers/StudentMenu';

const MainProvider: React.FC<MainProviderProps> = ({
  children,
  contextType
}) => {
  const { currentContext } = useAuthority();

  return (
    <ContextManagerProvider contextType={contextType}>
      <ClassDisciplinesProvider variant={Variant.STUDENT}>
        {currentContext.personType === PersonType.STUDENT ? (
          <AccessTimeProvider>{children}</AccessTimeProvider>
        ) : (
          children
        )}
      </ClassDisciplinesProvider>
    </ContextManagerProvider>
  );
};

const App: React.FC = () => {
  return (
    <AppContainer
      theme={studentTheme}
      contextType={ContextType.STUDENT}
      components={{
        MainProvider,
        Menu: StudentMenu,
        PageHeader: PeriodBanner
      }}
    >
      <Route index element={<Home />} />
      <Route path="/performace" element={<Performace />} />
      <Route path="discipline/:DisciplineId/*" element={<Discipline />} />
      <Route path="/messages/*" element={<Message />} />
      <Route
        path="library-object/:libraryObjectId"
        element={<LibraryObject />}
      />
      <Route
        path="library-object/:libraryObjectId/chapter/:chapterId"
        element={<LibraryObject />}
      />

      {createVestibularRoutes({
        pages: {
          title: 'ENEM & Vestibulares',
          literaryWorks: {
            isReadOnly: true
          },
          literaryWork: {
            isReadOnlyOnGeneral: true,
            questionsCanBe: 'answered'
          }
        }
      })}
    </AppContainer>
  );
};

export default App;
