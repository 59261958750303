import styled from 'styled-components';

export const NavigationContainer = styled.div`
  max-width: 389px;
  min-width: 389px;

  ${(props) => props.theme.breakpoints.down('lg')} {
    max-width: 100%;
    min-width: 100%;
  }

  height: 100vh;
  background-color: ${({ theme }) => theme.colors.content.background};
  box-shadow: inset -1px 1px 0px 0px rgb(0 0 0 / 10%);
`;
