import React from 'react';
import BoxEmpty from '@campus/components/assets/icons/box-empty.svg';
import { Container, Text, SubText, IconBoxEmpty } from './styles';

interface ICardEmpty {
  subTextView: boolean;
  height: number;
  width: number;
}

const CardEmpty = ({ subTextView, width, height }: ICardEmpty) => {
  return (
    <Container>
      <IconBoxEmpty
        src={BoxEmpty}
        alt="Imagem de uma caixa sem mensagem"
        width={width}
        height={height}
      />
      <Text>
        Você ainda não tem nenhuma mensagem
      </Text>
      {subTextView && (
        <SubText>
          Volte em breve!
        </SubText>
      )}
    </Container>
  );
};

export default CardEmpty;
