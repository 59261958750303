import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import { Card, Col, Row, Skeleton, CampusIcon } from '@campus/components';
import {
  useContextManager,
  useClassDisciplines,
  DisciplineCard,
  useWindowDimensions,
  Breakpoints,
  Variant
} from '@campus/commons';

import {
  Header,
  Title,
  Section,
  StyledButton,
  HeaderMobileButton
} from './styles';
import { Link, useLocation } from 'react-router-dom';
import { breakpointsAndCardsPerRow } from 'models/CardsPerRow';

const DisciplinesSection: React.FC = () => {
  const { breakpoint } = useWindowDimensions();
  const { search } = useLocation();
  const { classDisciplines: disciplines, loadingClassDisciplines: loading } =
    useClassDisciplines(Variant.STUDENT);

  const { classroom, period } = useContextManager();

  const cardsPerRow = breakpointsAndCardsPerRow[breakpoint];

  const listLength = disciplines?.length ?? 0;
  const countDisabledCards =
    listLength === 0
      ? cardsPerRow
      : Math.ceil(listLength / cardsPerRow) * cardsPerRow - listLength;

  return (
    <Section>
      <Header>
        <Title>
          Componentes da Turma <span>{classroom?.name}</span>
        </Title>
        {period.active && (
          <Link to={{ pathname: 'performace', search: search }}>
            {breakpoint === Breakpoints.xs ? (
              <HeaderMobileButton>MDC</HeaderMobileButton>
            ) : (
              <StyledButton variant="outline-primary">
                <CampusIcon name="grade" />
                MDC Consolidado
              </StyledButton>
            )}
          </Link>
        )}
      </Header>
      <Row className="gx-3 gy-3" xs={2} sm={3} md={4} lg={3} xl={5} xxl={5}>
        {loading ? (
          <>
            {[...Array(5)].map((_, index) => (
              <Col key={index}>
                <Skeleton width="100%" height="120px" />
              </Col>
            ))}
          </>
        ) : (
          <>
            {disciplines?.map((discipline) => (
              <Link
                key={uuidv4()}
                to={{
                  pathname: `/discipline/${discipline.id}`,
                  search: search
                }}
              >
                <Col>
                  <DisciplineCard discipline={discipline} />
                </Col>
              </Link>
            ))}
            {Array(countDisabledCards)
              .fill(0)
              .map((_, index) => (
                <Col key={index}>
                  <Card disabled fullHeight style={{ minHeight: 120 }} />
                </Col>
              ))}
          </>
        )}
      </Row>
    </Section>
  );
};

export default DisciplinesSection;
