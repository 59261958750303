import React from 'react';
import { useMessages } from '@campus/commons';

import { Container, StyledButton } from './styles';

const LoadMore = () => {
  const { loadMore, hasMoreMessages } = useMessages();

  return (
    <>
      {hasMoreMessages && (
        <Container>
          <StyledButton onClick={loadMore}>CARREGAR MAIS</StyledButton>
        </Container>
      )}
    </>
  );
};

export default LoadMore;
