import styled from 'styled-components';
import { Card } from '@campus/components';

export const Container = styled.div<{ $color?: string }>`
  /* height: calc(100vh - 206px); */
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
  margin-top: 10px;

  &::-webkit-scrollbar {
    width: 0;
    height: 0;
  }
`;

export const StyledCard = styled(Card)`
  margin: 0 12px 12px 12px;
  overflow: auto;
  height: 660px;
`;

export const SkeletoonRoot = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 20px;
`;
