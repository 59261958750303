import styled from 'styled-components';

export const Banner = styled.div`
  width: 100%;
  height: 95px;
  background-color: ${(props) => props.theme.colors.primary};
  color: #ffffff;

  h1 {
    font-size: 24px;
    font-weight: ${(props) => props.theme.weight.bold};
    line-height: 120%;
    margin-bottom: 0;
  }

  h2 {
    font-size: 12px;
    font-weight: ${(props) => props.theme.weight.regular};
  }

  ${(props) => props.theme.breakpoints.down('lg')} {
    height: inherit;
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    h1 {
      font-size: 16px;
    }
    h2 {
      font-size: 12px;
    }
  }

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: none;
  }
`;

export const Header = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  padding: 0 20px 8px;
  width: 100%;
  max-width: 1020px;
  margin: 0 auto;

  @media ${(props) => props.theme.mediaQuery.miniMobile} {
    flex-direction: column;
  }
`;

export const ChangeViewButton = styled.button`
  width: 143px;
  height: 24px;
  font-size: 12px;
  color: ${(props) => props.theme.colors.gray500};
  font-weight: ${(props) => props.theme.weight.semiBold};
  border: none;
  background: white;
  border-radius: 3px;
  padding: 4px 8px;
  transition: 0.2s all;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 38px;

  @media ${(props) => props.theme.mediaQuery.miniMobile} {
    margin-top: 15px;
    margin-bottom: 15px;
  }
`;

export const HeaderContent = styled.div`
  margin-top: 43px;

  @media ${(props) => props.theme.mediaQuery.miniMobile} {
    margin-top: 20px;
  }
`;

export const DivToPrint = styled.div`
  width: 1000px;
`;

export const DivToPrintParent = styled.div<{ $toPrintPdf: boolean }>`
  width: 100%;
  overflow-x: auto;
  overflow-y: auto;

  @media ${(props) => props.theme.mediaQuery.desktopUp} {
    overflow-y: hidden;
  }
  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: none;
    display: ${(props) => (props.$toPrintPdf ? 'block' : 'none')};
  }
`;

export const DivMobile = styled.div<{ $toPrintPdf: boolean }>`
  display: none;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    display: ${(props) => (props.$toPrintPdf ? 'none' : 'block')};
  }
`;
