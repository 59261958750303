import styled from 'styled-components';

export const Container = styled.div`
width: 100%;
height: 100%;
align-items: center;
justify-content: flex-start;
backgroundColor: transparent;
display: flex;
flex-shrink: 1;
flex-direction: column;
padding-top: 5%;
`;

export const IconBoxEmpty = styled.img`
display: block;
`;

export const Text = styled.h1`
font-style: normal;
font-weight: 600;
font-size: 16px;
line-height: 24px;

color: ${props => props.theme.colors.gray600};
`;

export const SubText = styled.span`
font-style: normal;
font-weight: 600;
font-size: 12px;
line-height: 16px;
color: ${props => props.theme.colors.gray400};
`;
