import React from 'react';
import { useNavigate } from 'react-router-dom';
import format from 'date-fns/format';

import { Col } from '@campus/components';
import { MessageContents, StatusIcon } from '@campus/commons';

import {
  StyledListGroupItem,
  StyledListGroupItemContent,
  StyledRowContent,
  DisciplineIcon,
  TitleContent,
  SubTitleContent,
  StyledBageWeight,
  StyledColTitle,
  StyledBadgeEvaluationTypeName,
  StyledColBadge
} from './styles';

interface IItem {
  content?: MessageContents;
}

const Item = ({ content }: IItem) => {
  const formatDate = (date: Date): string =>
    `${format(date, 'dd/MM/yyyy')} às ${format(date, 'HH:mm')}`;
  const navigate = useNavigate();

  return (
    <StyledListGroupItem
      key={content.id}
      onClick={() =>
        navigate(`/discipline/${content.discipline.id}/content/${content.id}`)
      }
    >
      <StyledListGroupItemContent>
        <DisciplineIcon color={content?.discipline?.color}>
          {content.discipline.name.substring(0, 3)}
        </DisciplineIcon>
        <Col>
          <StyledRowContent>
            <StyledColTitle>
              <TitleContent>{content.name}</TitleContent>
            </StyledColTitle>
            <StyledColBadge>
              <StyledBadgeEvaluationTypeName
                $color={content.evaluationTypeColor.replace(/hsl|\(|\)/g, '')}
              >
                {content.evaluationTypeName}
              </StyledBadgeEvaluationTypeName>
              <StyledBageWeight>
                {content?.totalWeight?.toString()?.replace('.', ',')} Pontos
              </StyledBageWeight>
            </StyledColBadge>
          </StyledRowContent>
          <StyledRowContent>
            {content.endTime && (
              <SubTitleContent>
                Esta atividade se encerra no dia{' '}
                <span>{formatDate(new Date(content.endTime))}</span>
              </SubTitleContent>
            )}
          </StyledRowContent>
        </Col>
      </StyledListGroupItemContent>
      <div>
        <StatusIcon status={content.state} statusName={content.stateText} />
      </div>
    </StyledListGroupItem>
  );
};

export default Item;
