import { Button } from '@campus/components';
import styled from 'styled-components';

export const Header = styled.section`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 10px;
`;

export const Title = styled.h2`
  grid-column: 1/4;
  font-size: 24px;
  font-weight: ${({ theme }) => theme.weight.semiBold};
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0px;

  & > span {
    color: ${({ theme }) => theme.colors.primary};
  }

  ${(props) => props.theme.breakpoints.down('sm')} {
    font-size: 16px;
  }
`;

export const StyledButton = styled(Button)`
  border: 2px solid;

  span {
    display: flex;
    justify-content: center;
    line-height: normal;
    align-items: center;
    font-weight: 500;
    font-size: 14px;
  }

  svg {
    margin-right: 7px;
  }
`;

export const HeaderMobileButton = styled(Button)`
  display: flex;
  height: 24px;
  padding: 10px 8px;
  justify-content: center;
  align-items: center;
  border-radius: 3px;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
`;

export const Section = styled.section`
  margin-top: 35px;

  @media ${(props) => props.theme.mediaQuery.mobile} {
    margin-top: 20px;
  }
`;
